import imgP1 from "../assets/p1.png";
import imgMovie from "../assets/movie.png";
import imgP3 from "../assets/p3.png";
import imgP4 from "../assets/haldoon.png";
import imgP5 from "../assets/f1.jpg";
import imgP6 from "../assets/ptt.jpg";
import imgP7 from "../assets/rm.png";
import imgP8 from "../assets/ai-chat.png";

export const ProjectList = [
  {
    name: "AI Chat",
    img: imgP8,
    skills: "Next.js, React.js, TypeScript, DaisyUI, OpenAI ChatGPT API",
    url: "https://ai-chat-ok.vercel.app/",
    githubUrl: "https://github.com/omerkarakas/ai-chat.git",
  },
  {
    name: "Rick and Morty Project",
    img: imgP7,
    skills: "React JS, Rest API, Ant Design",
    url: "https://rickn-morty.netlify.app/",
    githubUrl: "https://github.com/omerkarakas/rick-and-morty",
  },
  {
    name: "Personal Task Tracker",
    img: imgP6,
    skills: "React JS, Materia UI, Rest API, Node.js",
    url: "https://personal-tasktracker.netlify.app",
    githubUrl: "https://github.com/omerkarakas/personal-task-tracker",
  },
  {
    name: "Haldoon, The Search Engine",
    img: imgP4,
    skills: "React JS, Rest API",
    url: "http://haldoon.okarakas.com",
    githubUrl: "https://github.com/omerkarakas/haldoon",
  },
  {
    name: "E-Commerce website",
    img: imgP1,
    skills: "React JS",
    url: "https://classy-clothing.netlify.app",
    githubUrl: "https://github.com/omerkarakas/classy-clothing",
  },
  {
    name: "Movie Database",
    img: imgMovie,
    skills: "React JS, Rest API",
    url: "https://movies.okarakas.com",
    githubUrl:
      "https://github.com/omerkarakas/react-projects/tree/master/movies-app",
  },

  {
    name: "Win Calculator Clone",
    img: imgP3,
    skills: "React JS",
    url: "https://okarakas.com/calculator",
    githubUrl:
      "https://github.com/omerkarakas/react-projects/tree/master/calculator",
  },
  {
    name: "Formula 1 Race History",
    img: imgP5,
    skills: "NextJS, React JS",
    url: "https://formula1-ten.vercel.app",
    githubUrl: "https://github.com/omerkarakas/nextjs-formula1",
  },
];
